<template>
  <b-modal
    @change="onChange"
    :visible="showModal"
    :title="title"
    hide-footer
    centered
  >
    <b-form-timepicker
      v-model="timePickerValue"
      size="lg"
      now-button
      reset-button
      no-close-button
    ></b-form-timepicker>

    <b-button
      @click.prevent="onSubmit"
      class="mt-2 d-flex mx-auto"
      variant="primary"
      squared
    >
      Done
    </b-button>
  </b-modal>
</template>

<script>
export default {
  props: ["value", "title", "time"],
  data() {
    return {
      timePickerValue: this.time,
    };
  },
  computed: {
    showModal() {
      return this.value;
    },
  },
  methods: {
    onChange(isVisible) {
      this.$emit("input", isVisible);
    },
    onSubmit() {
      // making modal invisible
      this.onChange(false);
      this.$emit("submit", this.timePickerValue);
    },
  },
  watch: {
    time: function(newValue) {
      this.timePickerValue = newValue;
    },
  },
};
</script>
