<template>
  <b-row>
    <b-col offset-lg="3" lg="6" offset-sm="2" sm="8">
      <b-card>
        <!-- Day & Toggle -->
        <div class="d-flex align-items-center">
          <!-- Avatar (Day of Week) -->
          <b-avatar
            :variant="_working ? 'primary' : 'secondary'"
            :text="dayOfWeek"
            class="mr-3"
          />

          <!-- Day, Status & Toggle -->
          <div class="mr-auto d-flex flex-column">
            <span
              :class="!_working ? 'text-muted' : ''"
              class="text-capitalize"
            >
              <b>{{ form.day }}</b>
            </span>

            <!-- Closed -->
            <span v-if="!_working">Closed</span>
          </div>

          <b-form-checkbox v-model="form.working" switch />
        </div>

        <template v-if="_working">
          <!-- Horizontal Line -->
          <hr />

          <!-- Opening - to - Closing -->
          <div class="d-flex justify-content-between">
            <!-- Opening -->
            <div>
              <span>Opening Time</span>
              <b-row class="mx-auto align-items-center">
                <span class="mr-2">
                  {{ openTime }}
                </span>
                <b-icon-pencil @click="editOpenTime" font-scale="0.8" />
              </b-row>
            </div>

            <span>To</span>

            <!-- Closing -->
            <div>
              <span>Closing Time</span>
              <b-row class="mx-auto align-items-center justify-content-end">
                <span class="mr-2">
                  {{ closeTime }}
                </span>
                <b-icon-pencil @click="editCloseTime" font-scale="0.8" />
              </b-row>
            </div>
          </div>
        </template>
      </b-card>

      <div class="mt-2 d-flex justify-content-center">
        <b-button @click.prevent="saveChanges" variant="primary" class="mr-2">
          Save
        </b-button>
        <b-button @click.prevent="cancel">
          Cancel
        </b-button>
      </div>
    </b-col>

    <!-- Select Open Time in Modal -->
    <SetTimeModal
      title="Change Opening Time"
      :time="form.openTime"
      v-model="showOpenTimeModal"
      @submit="updateOpenTime"
    />

    <SetTimeModal
      title="Change Closing Time"
      :time="form.closeTime"
      v-model="showCloseTimeModal"
      @submit="updateCloseTime"
    />
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { updateBusinessDay } from "../../../../apis/merchant";
import SetTimeModal from "../../../../components/store/businessHours/SetTimeModal.vue";

export default {
  components: { SetTimeModal },
  created() {
    this.initBusinessHourForm();
  },
  data() {
    return {
      form: {
        day: "",
        working: false,
        openTime: "",
        closeTime: "",
      },
      showOpenTimeModal: false,
      showCloseTimeModal: false,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      businessHours: "merchant/businessHours/getBusinessHours",
    }),
    dayOfWeek() {
      const day = this.form.day;
      return day ? day.charAt(0) : " ";
    },
    _working() {
      return this.form.working;
    },
    openTime() {
      const openTime = this.form.openTime;
      if (openTime) {
        return openTime;
      }
      return "Edit";
    },
    closeTime() {
      const closeTime = this.form.closeTime;
      if (closeTime) {
        return closeTime;
      }
      return "Edit";
    },
  },
  methods: {
    ...mapActions({
      setBusinessHours: "merchant/businessHours/setBusinessHours",
    }),
    initBusinessHourForm() {
      const day = this.$route.params.day;
      this.form = { ...this.businessHours[day], day };
    },
    editOpenTime() {
      this.showOpenTimeModal = true;
    },
    editCloseTime() {
      this.showCloseTimeModal = true;
    },
    updateOpenTime(newValue) {
      this.form.openTime = newValue;
    },
    updateCloseTime(newValue) {
      this.form.closeTime = newValue;
    },
    saveChanges() {
      const form = this.form;
      const day = form.day;
      updateBusinessDay(day, form, this.token).then((data) => {
        this.setBusinessHours(data).then(() => {
          // after saved successfully, update
          this.cancel();
        });
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
